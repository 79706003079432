<template>
    <BasePage>
      <span v-if="isLoading">
        <font-awesome-icon :icon="['fas', 'spinner']" spin /> {{ $t('labels.loading') }}...
      </span>
      <div v-else>
        <PageTitle :title="`${prospect.name} ${prospect.lastName} ✨`" />

        <!-- Filters -->
        <div class="my-3 border-b border-slate-200 dark:border-slate-700">
          <div v-if="sections.length == 0">El usuario no tiene configurado poder visualizar ninguna subseccion dentro de detalle</div>
          <ul v-else class="text-sm font-medium flex flex-nowrap -mx-4 sm:-mx-6 lg:-mx-8 overflow-x-scroll no-scrollbar">
            <li
              v-for="section in sections"
              :key="section.id"
              @click="selectedSection = section.id"
              class="pb-3 mr-6 last:mr-0 first:pl-4 sm:first:pl-6 lg:first:pl-8 last:pr-4 sm:last:pr-6 lg:last:pr-8"
            >
              <a
                class="whitespace-nowrap dark:hover:text-slate-300"
                :class="selectedSection == section.id ? 'text-indigo-500 hover:text-indigo-600' : 'text-slate-500 hover:text-slate-600'"
                :href="section.href"
              >
                {{ section.name }}
              </a>
            </li>
          </ul>
        </div>
        <!-- Content -->
        <div class="bg-white dark:bg-slate-800 shadow-lg rounded-sm mb-8">
          <div v-if="selectedSection === 'section1'">
            <GeneralPanel :key="componentKey"
            :is-loading_="this.isLoading" @save="updateProspect" @force-render="forceRender" :can-edit="showEdit"
            :client-todos_="this.clientTodos.filter(todo => todo.type == 'PROSPECT')" :prospect="this.prospect" :categories="this.categories" />
          </div>

          <div v-else-if="selectedSection === 'section2'">
            <ProspectionDataPanel :key="componentKey" :can-edit="showEdit" :is-loading="this.isLoading" @save="updateProspect" @force-render="forceRender" :prospect="this.prospect" />
          </div>
          <div v-else-if="selectedSection === 'section3'">
            <ContractsPanel :key="componentKey" :prospect-id="this.prospectId" :client-documents_="this.clientProducts.filter(element => element.stepExtras?.length > 0)"/>
          </div>
          <div v-else-if="selectedSection === 'section4'">
            <ServicesPanel :key="componentKey" :can-edit="showEdit"
            :all-products="this.products"
            :prospect-id="this.prospectId" :products_="this.clientProducts" />
          </div>
          <div v-else-if="selectedSection === 'section5'">
            <InteractionsPanel :key="componentKey" :prospect-id="this.prospectId" :client-interactions_="this.clientInteractions"/>
          </div>
          <div v-else-if="selectedSection === 'section6'">
            <DocumentsPanel :key="componentKey" :prospect-id="this.prospectId" :client-documents_="this.clientDocuments" :can-edit="showEdit"/>
          </div>
          <div v-else-if="selectedSection === 'section7'">
            <TodosPanel :key="componentKey" :list_="[...this.clientTodos.filter(todo => todo.type == 'PROSPECT').map(todo => ({ todo })), ...this.clientProducts.map(c => [...c.extras, ...c.contractExtras, ...c.stepExtras]).flat()]"/>
          </div>              
        </div>
      </div>
    </BasePage>
  </template>
  
  <script>
  import { fetchData } from '@/utils/fetchData.js';
  import { loadFromLocalStorage } from '@/utils/loadFromLocalStorage.js';
  import { hasSpecificPermission } from '@/utils/hasSpecificPermission.js';
  import {extractUniquePermissions} from '@/utils/extractUniquePermissions.js';
  import {transformPermissionsToSections} from '@/utils/transformPermissionsToSections.js';

  import Swal from 'sweetalert2';
  import BasePage from '@/components/BasePage.vue';
  import api from '@/interceptors/axiosInterceptos'; 
  import PageTitle from '@/components/PageTitle.vue';
  import TodosPanel from '@/partials/prospects/detail/TodosPanel.vue';
  import GeneralPanel from '@/partials/prospects/detail/GeneralPanel.vue';
  import ServicesPanel from '@/partials/prospects/detail/ServicesPanel.vue';
  import DocumentsPanel from '@/partials/prospects/detail/DocumentsPanel.vue';
  import ContractsPanel from '@/partials/prospects/detail/ContractsPanel.vue';
  import InteractionsPanel from '@/partials/prospects/detail/InteractionsPanel.vue';
  import ProspectionDataPanel from '@/partials/prospects/detail/ProspectionDataPanel.vue';
  export default {
    name: 'ProspectDetailPage',
    components: {
      BasePage,
      PageTitle,
      TodosPanel,
      GeneralPanel,
      ServicesPanel,
      DocumentsPanel,
      ContractsPanel,
      InteractionsPanel,
      ProspectionDataPanel
    },
    data(){
        return {
          componentKey: 0,
          showEdit: false,
            prospectId: null,
            isLoading: false,
            products: [],
            categories: [],
            clientInteractions: [],
            clientDocuments: [],
            clientProducts: [],
            clientContracts: [],
            clientTodos: [],
            prospect: {},
            selectedSection: null,
            sections: [],
            clients: [],
            prospects: [],
            sources: [],
            users: []
        }
    },
    methods: {
        forceRender(){
            const prospectId = this.$route.params.id;
            const prospects = JSON.parse(localStorage.getItem(this.stringTypeClient)) || [];

            const temp = prospects.find(prospect => prospect.id === prospectId) || {};
            this.prospect = temp;
        },
        fetchProspectFromLocalStorage(prospectId) {
            const prospects = JSON.parse(localStorage.getItem(this.stringTypeClient)) || [];
            return prospects.find(prospect => prospect.id === prospectId) || false;
        },
        async updateProspect(formData){
          try {
            this.isLoading = true;
            const token = localStorage.getItem('token');
            const headers = { 
              "authorization": `Bearer ${token}`,
            };
            const response = await api.put(`${process.env.API_URL}/prospects/${this.prospectId}`, formData, { headers });

            if(response.status == 200){
              Swal.fire({
                  icon: 'success',
                  title: 'Exito!',
                  text: 'Actualizado exitosamente.',
              });
            }
            const temp = localStorage.getItem(this.stringTypeClient);
            let prospectsList = temp ? JSON.parse(temp) :[];
                
            const index = prospectsList.findIndex((prospect) => prospect.id === this.prospectId);
            if (index !== -1) {
              // Remove the item from the array
              prospectsList.splice(index, 1);
              // Insert the updated item at the original index
              prospectsList.splice(index, 0, response.data);
              localStorage.setItem(this.stringTypeClient, JSON.stringify(prospectsList));
              this.prospect = response.data;
            }
            this.isLoading = false;
          } catch (error) {
            Swal.fire({
              icon: 'error',
              title: 'Error!',
              text: error.response.data['error'] ?? error.response.data['message'],
            });
            this.isLoading = false;
            const prospectId = this.$route.params.id;
            const prospects = JSON.parse(localStorage.getItem(this.stringTypeClient)) || [];

            const temp = prospects.find(prospect => prospect.id === prospectId) || {};
            this.prospect = temp;
          }
          loadFromLocalStorage('clientTodos', 'clientTodos', this);
        },
        async fetchClientTodos(id){
          return fetchData(`todos/${id}`, 'clientTodos', 'clientTodos', this, true, true);
        },
        getData(id){
          Promise.all([
            fetchData(`roles`, 'roles', 'roles', this),
            fetchData(`client-interactions/${id}`, 'clientInteractions', 'clientInteractions', this),
            fetchData(`client-documents/prospects/${id}`, 'clientDocuments', 'clientDocuments', this),
            fetchData(`categories`, 'categories', 'categories', this),
            fetchData(`client-products/${id}`, 'clientProducts', 'clientProducts', this),
            fetchData(`products/see`, 'products', 'products', this),
          ]).then((results) => {
            this.componentKey++
          })
        }
    },
    async created() {
      this.prospectId = this.$route.params.id;
      
      this.stringTypeClient = this.$route.path.includes('prospects') ? 'prospects' : 'clients'
      this.prospect = this.fetchProspectFromLocalStorage(this.prospectId);
      if(!this.prospect){
        this.isLoading = true
        Promise.all([
          await fetchData(`prospects/${false}`, 'clients', 'clients', this, false),
          await fetchData(`prospects/${true}`, 'prospects', 'prospects', this, false),
          await fetchData(`sources`, 'sources', 'sources', this, false),
          await fetchData(`users`, 'users', 'users', this, false),
        ])
        this.prospect = this.fetchProspectFromLocalStorage(this.prospectId)
        this.isLoading = false
      }
      if(!this.prospect.isProspect){
        this.$router.push(`/clients/${this.prospectId}`);
      }
      this.stringTypeClient = this.prospect.isProspect ? 'prospects' : 'clients'
      hasSpecificPermission('showEdit', 'edit', [this.prospect.isProspect ? "edit_prospect" : 'edit_client'], this)
      loadFromLocalStorage('clientProducts', 'clientProducts', this);
      loadFromLocalStorage('categories', 'categories', this);
      loadFromLocalStorage('clientInteractions', 'clientInteractions', this);
      loadFromLocalStorage('products', 'products', this);
      loadFromLocalStorage('clientTodos', 'clientTodos', this);
      const uniquePermissions = extractUniquePermissions('subsection');
      this.sections = transformPermissionsToSections(uniquePermissions, this.stringTypeClient, this)
  
      this.clientTodos = await this.fetchClientTodos(this.prospectId);
      this.componentKey++
      
      this.getData(this.prospectId)
      
    }
  }
  </script>
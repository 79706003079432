<template>
  <ModalBasic id="document-form-modal" :modalOpen="modalOpen" @close-modal="modalOpen = false, clean()" :title="isEditing ? 'Editar Documento' : 'Agregar Documento'" :size="'w-1/2'">
    <!-- Modal content -->
    <DocumentForm :key="componentKey" :is-loading="isLoading" @handle-click:value="isEditing ? editSubmit($event) : addDocument($event)" @close-modal="modalOpen = false" :form-data_="document" />
  </ModalBasic>
    <div class="grow">
      <!-- Panel body -->
      <div class="p-6 space-y-6">
        <h2 class="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-5">Documentos</h2>
        <form @submit.prevent="add">
          <div class="grid grid-cols-1 sm:grid-cols-6 gap-2">
              <div v-if="canEdit" class="sm:col-span-3" > 
                <label class="block text-sm font-medium mb-1">
                    {{ $t('labels.documents') }}:
                </label>
                <div class="flex w-full">
                    
                  <input v-if="this.documents?.length < 1" id="document" class="form-input w-full" type="text" placeholder="Crea al menos un documento" :disabled="true" />
                  
                  <DropdownFullQuestions v-else
                  :property-key="'name'"
                  :dropdownOptions="this.documents" 
                  :model="this.formData.document" @update:model="updateSelectedDocument" />
  
                  <div class="pr-2"></div>
                  <button class="text-white-500 hover:text-white-600 rounded-full">
                    <svg @click.stop="modalOpen = true" class="w-4 h-8 fill-current opacity-50 shrink-0" viewBox="0 0 16 16">
                        <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                    </svg>
                  </button>
                </div>
                <button :disabled="isLoading || this.documents?.length < 1" @click.stop="addDocumentToProduct" class="btn bg-indigo-500 hover:bg-indigo-600 text-white mt-2">
                  <span v-if="!isLoading">{{ $t('buttons.add') }}</span>
                  <span v-else>
                    <font-awesome-icon :icon="['fas', 'spinner']" spin /> {{ $t('labels.loading') }}...
                  </span>
                </button>
              </div>
              <div class="sm:col-span-3" > 
                  <SubCategoriesTable 
                  :is-draggable="false"
                  :can-edit="canEdit"
                  :title="'Documentos del producto'" 
                  :empty-label="'Selecciona un documento del dropdown para agregarlo a los documentos del producto'"
                  :property-key="'document.name'"
                  :values="this.productDocuments" :is-loading="isLoading" 
                  @edit="editDocument"
                  @update:model="deleteProductDocument"
                  @updateorder:model=update
                  />
              </div>
          </div>
      </form>
      </div>
    </div>  
  </template>
  
  <script>
  import { editData } from '@/utils/editData.js';
  import { postData } from '@/utils/postData.js';
  import { deleteData } from '@/utils/deleteData.js';
  
  import Swal from 'sweetalert2';
  import ModalBasic from '@/components/ModalBasic.vue';
  import SubCategoriesTable from './SubCategoriesTable.vue';
  import DropdownFullQuestions from './DropdownFullQuestions.vue';
  import DocumentForm from '@/partials/products/detail/DocumentPanel/DocumentForm.vue'
  
  export default {
    name: 'DocumentsPanel',
    components: {  
        ModalBasic,
        DocumentForm,
        SubCategoriesTable,
        DropdownFullQuestions,
    },
    props: {
      productId: String,
      documents_: Array,
      productDocuments_: Array,
      canEdit: {
        type: Boolean,
        default: function(){
          return false
        }
      },
    },
    data(){
      return {
        componentKey: 0,
        modalOpen: false,
        documents: this.documents_,
        productDocuments: this.productDocuments_,
        isLoading: false,
        isEditing: false,
        editingId: null,
        formData: {
          document: this.documents_.length > 0 ?this.documents_[0] : null,
          expenses: []
        },
        document: {
            name: '',
            description: '',
            requestDuringSignature: false,
            formats: ['.pdf'],
        }
      }
    },
    methods: {
      clean(){
        this.document = {
          name: '',
          description: '',
          requestDuringSignature: false,
          formats: ['.pdf'],
        }
        this.isEditing = false;
        this.editingId = null;
        this.componentKey++;
      },
      async editSubmit(formData){
        await editData(`product-documents/${this.editingId}`, formData, 'El documento se ha guardado con exito.', 'productDocuments', 'productDocuments', this, true);
        this.clean()
      },
      editDocument(document){
        this.isEditing = true;
        this.editingId = document.id;
        
        this.document = document.document
        this.componentKey++
        this.modalOpen = true;
      },
      async addDocument(){
        const document = {
          name: this.document.name,
          description: this.document.options,
          requestDuringSignature: this.document.requestDuringSignature,
          formats: this.document.formats
        }
        const response = await postData('documents', document, this);
        if (!response) {
            return
        };
        this.documents.push(response);
        this.formData.document = response;
        await this.addDocumentToProduct();
        this.document = {
            name: '',
            description: '',
            requestDuringSignature: false,
            formats: ['.pdf'],
        };
        this.modalOpen = false;
      },
      async deleteProductDocument(index){
        const id = this.productDocuments[index].id
        const response = await deleteData('product-documents', id, this, 'El documento del producto se a eliminado exitosamente.', true, true)
        if(!response){
            return
        }
        this.productDocuments.splice(index, 1);
        localStorage.setItem('productDocuments', JSON.stringify(this.productDocuments));
      },
      async addDocumentToProduct(){
        const body = {
          productId: this.productId,
          documentId: this.formData.document.id
        }
        const response = await postData('product-documents', body, this);
        if (!response) {
          return
        };
        Swal.fire({
          icon: 'success',
          title: 'Exito!',
          text: 'El documento se agrego al producto.',
        });
        this.productDocuments.push(response);
        localStorage.setItem('productDocuments', JSON.stringify(this.productDocuments));
      },
      async update(elements){
        const productId = this.$route.params.id;
        var body = [];
        for (let i = 0; i < elements.length; i++) {
          body.push({
            id: elements[i].id,
            index: i + 1,
            documentId: elements[i].document.id,
            productId: productId,
          });
        }
        const response = await editData(`product-documents`, body, 'El orden se modifico correctamente.', 'productDocuments', 'productDocuments', this, true);
        if (!response) {
          return
        };
        this.productDocuments = response;
        localStorage.setItem('productDocuments', JSON.stringify(this.productDocuments));
        this.$emit('forceRender');
      },
      updateSelectedDocument(document){
        this.formData.document = document;
      },
    },
  }
  </script>
<template>
  <button @click.prevent.stop="modalOpen = true" class="my-2 text-left bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700 px-4">
    <!-- Body -->
    <div class="flex items-center justify-between ">
      <div class="flex items-center my-2">
        <!-- Title -->
        
          <EmptyToolTip v-if="shouldShowToolTip" :bg="'dark'" class="mr-2" :color="isToday? 'text-yellow-500' : 'text-red-500'">
          <div class="text-xs whitespace-nowrap" :class="isToday? 'text-yellow-500' : 'text-red-500'">
            Fecha Limite: {{ this.dueDate }}
          </div>
        </EmptyToolTip>
        <h2 class="font-semibold text-slate-800 dark:text-slate-100">{{element.todo.name}} 
          <span v-if="element.todo.type.includes('PRODUCT')">- {{ element.todo.clientproduct?.name }}</span> 
          <span v-if="element.todo?.client !== null && element.todo?.client !== undefined"> - {{ element.todo?.client?.name }} {{element.todo?.client?.lastName}}</span>
        </h2>
        
        <!-- Content -->
      </div>
      <div>

        <font-awesome-icon v-if="element.todo.type == 'PROSPECT' && element.todo.name.includes('Cita')" :icon="['fas', 'calendar-check']" class="text-indigo-400" />
        <font-awesome-icon v-else-if="element.todo.type == 'PROSPECT' && element.todo.name.includes('Llamada')" :icon="['fas', 'phone']" class="text-indigo-400" />
        <font-awesome-icon v-else-if="element.todo.type == 'PRODUCT-DOCUMENT'" :icon="['fas', 'file']" class="text-indigo-400" />
        <font-awesome-icon v-else-if="element.todo.type == 'PRODUCT-CONTRACT'" :icon="['fas', 'file-contract']" class="text-indigo-400" />
        <font-awesome-icon v-else-if="element.todo.type == 'PRODUCT-STEP'" :icon="['fas', 'list-check']" class="text-indigo-400" />
        <font-awesome-icon v-else-if="element.todo.type == 'PRODUCT-SURVEY'" :icon="['fas', 'question']" class="text-indigo-400" />
      </div>
  </div> 
  <div class="flex mb-2">
    <div class="flex shrink-0 -space-x-3 -ml-px">
      <a class="block" href="#0">
        <AvatarLetter :name="element.todo.user?.username" :color="element.todo.user?.color"/>
      </a>
    </div>
  </div>
        
      
    
    <!-- Footer -->
    
  </button>
  <ModalBasic 
    :size="'max-w-2xl'"
    id="modal" :modalOpen="modalOpen" @close-modal="modalOpen = false" title="Detalle">
    <form>
      <div class="px-5 py-4">
          <div class="space-y-3">  
            <div class="space-y-4 grid grid-cols-1 gap-x-6 sm:grid-cols-6 mb-4 ">

              <div class="sm:col-span-6" >
                  <label class="block text-sm font-medium mb-1" for="name">Nombre <span class="text-rose-500">*</span></label>
                  <input id="name" :disabled="true" v-model="element_.todo.name" class="form-input w-full px-2 py-1" type="text" />
              </div>
              <div class="sm:col-span-6" >
                  <label class="block text-sm font-medium mb-1" for="description">Descripcion </label>
                  <textarea id="description" v-model="element_.todo.description" class="form-textarea w-full px-2 py-1" rows="4"></textarea>
              </div>
              <div class="sm:col-span-6" >
                  <label class="block text-sm font-medium mb-1" for="notes">Notas </label>
                  <textarea id="notes" v-model="element_.todo.notes" class="form-textarea w-full px-2 py-1" rows="4"></textarea>
              </div>
              <div class="sm:col-span-3" >
                  <label class="block text-sm font-medium mb-1" for="dueDate">Fecha Limite <span class="text-rose-500">*</span></label>
                  <input id="dueDate" :disabled="true" v-model="element_.todo.dueDate" class="form-input w-full px-2 py-1" type="date" required />
                  <input id="dueDateHour" :disabled="true" v-model="element_.todo.dueDateHour" class="form-input w-full px-2 py-1" type="time" required />
              </div>
              <div class="sm:col-span-3" >
                <label class="block text-sm font-medium mb-1" for="realizedDate">Fecha Realizado <span class="text-rose-500">*</span></label>
                  <input id="realizedDate" :disabled="true" v-model="element_.todo.realizedDate" class="form-input w-full px-2 py-1" type="date" required />
                  <input id="realizedDateHour" :disabled="true" v-model="element_.todo.realizedDateHour" class="form-input w-full px-2 py-1" type="time" required />
                  
              </div>
              <div class="sm:col-span-6" v-if="element_.todo.user != undefined">
                  <label class="block text-sm font-medium mb-1" for="name">Usuario asignado <span class="text-rose-500">*</span></label>
                  <input id="name" :disabled="true" v-model="element_.todo.user.username" class="form-input w-full px-2 py-1" type="text" />
              </div>
              <div class="sm:col-span-3" v-if="element_.todo.client != undefined">
                  <label class="block text-sm font-medium mb-1" for="name">Cliente <span class="text-rose-500">*</span></label>
                  <input id="name" :disabled="true" v-model="element_.todo.client.name" class="form-input w-full px-2 py-1" type="text" />
                  <input id="name" :disabled="true" v-model="element_.todo.client.lastName" class="form-input w-full px-2 py-1" type="text" />
              </div>
              <div class="sm:col-span-6" v-if="element_.todo.clientproduct != undefined">
                  <label class="block text-sm font-medium mb-1" for="name">Producto <span class="text-rose-500">*</span></label>
                  <input id="name" :disabled="true" v-model="element_.todo.clientproduct.name" class="form-input w-full px-2 py-1" type="text" />
              </div>
            </div>
          </div>
      </div>
    </form>
    <span v-if="element.todo.type == 'PRODUCT-DOCUMENT'">
      <!-- {{ element.clientDocument }} -->
    </span>
    <span v-else-if="element.todo.type == 'PRODUCT-CONTRACT'">
      <!-- {{ element.clientContract }} -->
    </span>
    <span v-else-if="element.todo.type == 'PRODUCT-STEP'">
      <!-- {{ element.clientStep }} -->
    </span>
  </ModalBasic>

</template>

<script>
import ModalBasic from '@/components/ModalBasic.vue';
import AvatarLetter from '@/components/AvatarLetter.vue'
import EmptyToolTip from '@/components/EmptyToolTip.vue';

export default {
  name: 'TodosTableItem',
  props: {
    element: Object
  },
  components: {
    ModalBasic,
    AvatarLetter,
    EmptyToolTip,
  },
  data(){
    var dueDate = '';
    var element_ = JSON.parse(JSON.stringify(this.element));;
    if (this.element.todo?.dueDate !== null && this.element.todo?.dueDate !== undefined){
      const date = new Date(this.element.todo.dueDate);
      dueDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')} ${date.toLocaleTimeString([], { hour12: true, hour: 'numeric', minute: 'numeric' })}`
    }
    if(this.element.todo?.dueDate != null){
      const date = new Date(this.element.todo.dueDate);
      element_.todo.dueDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`
      element_.todo.dueDateHour = `${String(date.getHours()).padStart(2, '0')}:00`
    }
    if(this.element.todo?.realizedDate != null){
      const date = new Date(this.element.todo.realizedDate);
      element_.todo.realizedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`
      element_.todo.realizedDateHour = `${String(date.getHours()).padStart(2, '0')}:00`
    }
    
    return {
      modalOpen: false,
      dueDate,
      element_: element_
    }
  },
  computed: {
    shouldShowToolTip() {
      const now = new Date();
      let formattedDueDate = this.dueDate.replace(' p.m.', ' PM').replace(' a.m.', ' AM');
  
      // Use Date.parse to convert the formatted string into a Date object
      const dueDate = new Date(Date.parse(formattedDueDate));

      // If the date is invalid, return false early
      if (isNaN(dueDate.getTime())) {
        console.error('Invalid dueDate:', this.dueDate);
        return false;
      }
      
      // Calculate the difference in milliseconds
      const timeDifference = dueDate - now;
      
      // Convert the time difference to hours
      const hoursDifference = timeDifference / (1000 * 60 * 60);
      return (hoursDifference <= 24 || hoursDifference < 0) && this.element.todo.status !== 'DONE';
    },
    isToday() {
      const now = new Date();
      const dueDate = new Date(this.dueDate);
      
      // Calculate the difference in milliseconds
      const timeDifference = now - dueDate;
      
      // Convert the time difference to hours
      const hoursDifference = timeDifference / (1000 * 60 * 60);

      return (hoursDifference <= 24) && this.element.todo.status !== 'DONE';
    }
  },
  methods: {
    updateModel(option){
      const index = this.step.formats.indexOf(option);
      if (index === -1) {
          // If the option is not already in the array, push it
          this.step.formats.push(option);
      } else {
          // If the option is already in the array, remove it
          this.step.formats.splice(index, 1);
      }   
    },
  },
}
</script>
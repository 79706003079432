import Swal from 'sweetalert2';

import { postData } from '@/utils/postData.js';
import api from '@/interceptors/axiosInterceptos';
import {isValidEmail} from '@/utils/isValidEmail.js'

export async function getWeeksDocument(context){
    const token = localStorage.getItem('token');
    const response = await api.get(`${process.env.API_URL}/prospection/weeks-document/${context.formData.client.curp}`, {
      headers: {
        'Content-Type': 'multipart/form-data',
        "authorization": `Bearer ${token}`,
      },
      responseType: 'blob'
    }).then(response => {
      // Set the document content received from the backend
      context.documentLoadingSuccess = true;
      context.formData.documentUrl = URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
      
    })
    .catch(error => {
      console.error('Error fetching document:', error);
      context.documentLoadingSuccess = false;
      context.error = true;
      // Handle error if needed
    });
};

export function downloadPdfDocument(context) {
    const link = document.createElement('a');
    link.href = context.formData.documentUrl;
    link.download = 'downloaded.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export async function searchProspectMethod(context){
    context.documentLoadingSuccess = false;
    context.formData.values = [];
    context.formData.aplicableProducts = [];
    context.formData.errors = [];
    context.formData.documentUrl = null;
    context.componentKey += 1;
    try {
      context.isLoading = true;
      const token = localStorage.getItem('token');
      const headers = { 
          "authorization": `Bearer ${token}`,
      };
      const response = await api.get(`${process.env.API_URL}/prospection/find-products/${context.formData.client.curp}/notificaciones@afieconsultores.com/${'a'}/afie`, { headers });
      
      if(response.status == 200){
          context.formData.values = response.data.values;
          context.formData.aplicableProducts = response.data.aplicableProducts;
          context.formData.errors = response.data.errors;
          context.componentKey += 1;
          await getWeeksDocument(context)
      }
      context.showData = true;
    } catch (error) {
      Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: error?.response?.statusText ?? error,
      });
    }
    context.isLoading = false;
};

export function transformValue(values) {
    return values.reduce((acc, obj) => {
      if (obj.items && Array.isArray(obj.items)) {
        obj.items.forEach(item => {
          if (item.label !== undefined && item.value !== undefined) {
            acc[item.label] = item.value;
          }
        });
      }
      return acc;
    }, {});
};

export async function addProspect(context){
    if (context.formData.client.email == null || context.formData.client.email == "" || !isValidEmail(context.formData.client.email) || context.formData.client.phone == null || context.formData.client.phone == ""){
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'Proporciona un email y telefono por favor',
        
      })
      return;
    }
    context.isLoading = true;
    var client = context.formData.client
    var {name, lastName, rfc, age, law73, currentlyActive, ...values} = transformValue(context.formData.values)
    
    client.name = name
    client.lastName = lastName
    client.rfc = rfc
    values.requestedErrors = JSON.stringify(context.formData.errors)
    values.law73 = law73 !== null ? law73 === 73 : null
    values.currentlyActive = currentlyActive === 'Activo'
    values.requestedDate = new Date()
    const body = {
      client: client,
      values: values,
      applicableProducts: context.formData.aplicableProducts
    }
    const response = await postData('prospects', body, context, true);
    if (!response) {
      return;
    };
    Swal.fire({
      icon: 'success',
      title: 'Exito!',
      text: 'El prospecto se a creado exitosamente.',
    });
    context.isLoading = false;
    context.$router.push('/prospects');
};

export async function sendToClientMethod(context){
    if (context.formData.client.email == null || context.formData.client.email == "" || !isValidEmail(context.formData.client.email)){
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'Proporciona un email y telefono por favor',
        
      })
      return;
    }
    const response = await postData('prospection/download-report', {
      sendByEmail: true,
      email: context.formData.client.email,
      phone: context.formData.client.phone,
      curp: context.formData.client.curp,
      values: context.formData.values,
      aplicableProducts: context.formData.aplicableProducts,
      errors: context.formData.errors,
    }, context, true);
    if (!response) {
      return;
    };
    Swal.fire({
      icon: 'success',
      title: 'Exito!',
      text: 'El reporte se a enviado exitosamente.',
    });
};

export async function downloadReport(context){
    const response = await postData('prospection/download-report', {
      sendByEmail: false,
      curp: context.formData.client.curp,
      values: context.formData.values,
      aplicableProducts: context.formData.aplicableProducts,
      errors: context.formData.errors,
    }, context, true);
    if (!response) {
      return;
    };
    context.isLoading = true;
    const token = localStorage.getItem('token');
    const formData = new FormData();
    formData.append('path', `${context.formData.client.curp}/report.pdf`);
    
    try {
      const response = await api.post(`${process.env.API_URL}/documents/stream/${context.formData.client.curp}%2Freport.pdf`, formData,{
      
        headers: {
          'Content-Type': 'multipart/form-data',
          "authorization": `Bearer ${token}`,
        },
        responseType: 'blob'
      })
      
      const documentUrl = URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
      const link = document.createElement('a');
      link.href = documentUrl;
      link.download = 'downloaded.pdf';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: error.response.statusText,
        
      })
    } 
    context.isLoading = false;
};

export async function sumbitAvaclickForm(context, formData){
  const response = await postData('prospection/avaclick-valuation', {
    "Inmueble": {
      "Identificador": Math.random().toString(36).slice(-5),
      "Latitud": formData.coordinates.lat,
      "Longitud": formData.coordinates.lng,
      "Domicilio": formData.address,
      "TipoInmueble": formData.typeProperty,
      "Terreno": formData.terrain,
      "Construccion": formData.construction,
      "Recamara": formData.rooms,
      "Banios": formData.bathrooms,
      "Aniocompra": formData.constructionYear,
      "PrecioCompra": formData.price,
      "Cocina": formData.kitchen,
      "Conservacion": formData.conservation
    }
  }, context, true);
  return response
};

export async function submitTwingForm(context){
  const response = await postData('prospection/avaclick-valuation/twins', {
    "Comparables": {
        "Folio": context.formData.folio,
        "Gemelos": context.selectedImages
      }
  }, context, true);
  return response
}
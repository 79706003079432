<template>
  <ModalBasic :key="componentKey" id="feedback-modal" :modalOpen="addStepModalOpen" @close-modal="addStepModalOpen = false, clean()" :title="isEditing ? 'Editar Paso' : 'Agregar Paso'">
      <!-- Modal content -->
      <StepForm 
      @close-modal="addStepModalOpen = false"
      :form-data_="this.step" :is-loading="isLoading" @handle-click:value="isEditing ? submitEdit($event) : addStep($event)" 
      :roles="roles" :notifications="notifications" :product-steps="productSteps" :is-editing="isEditing" :step-index="stepIndex"/>
  </ModalBasic>
  <div class="grow">
    <!-- Panel body -->
    <span v-if="isLoading">
      <font-awesome-icon :icon="['fas', 'spinner']" spin /> {{ $t('labels.loading') }}...
    </span>
    <div v-else class="p-6 space-y-6">
      <h2 class="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-5">{{ $t('labels.steps') }}</h2>
      <form @submit.prevent="add">
        <div class="grid grid-cols-1 sm:grid-cols-6 gap-2">
            <div v-if="canEdit" class="sm:col-span-3" > 
              <label class="block text-sm font-medium mb-1">
                  {{ $t('labels.steps') }}:
              </label>
              <div class="flex w-full">
                  
                  <input v-if="this.steps?.length < 1" id="email" class="form-input w-full" type="text" placeholder="Crea al menos un paso" :disabled="true" />
                  
                  <DropdownFull v-else :dropdownOptions="this.steps" :model="this.formData.step" @update:model="updateSelectedStep" />

                  <div class="pr-2"></div>
                  <button class="text-white-500 hover:text-white-600 rounded-full">

                      <svg @click.stop="addStepModalOpen = true" class="w-4 h-8 fill-current opacity-50 shrink-0" viewBox="0 0 16 16">
                          <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                      </svg>
                  </button>
              </div>
              <button :disabled="isLoading || this.steps?.length < 1" @click.stop="addStepToProduct" class="btn bg-indigo-500 hover:bg-indigo-600 text-white mt-2">
                <span v-if="!isLoading">{{ $t('buttons.add') }}</span>
                <span v-else>
                  <font-awesome-icon :icon="['fas', 'spinner']" spin /> {{ $t('labels.loading') }}...
                </span>
              </button>
            </div>
            <div class="sm:col-span-3" > 
                <SubCategoriesTable 
                :can-edit="canEdit"
                :title="'Pasos del producto'" 
                :empty-label="'Selecciona un paso del dropdown para agregarlo a los pasos requeridos para el producto'"
                :property-key="'step.name'"
                @edit="editStep"
                :values="this.productSteps" :is-loading="isLoading" :model="this.formData.expensesList" @update:model="deleteProductStep"/>
            </div>
        </div>
    </form>
    </div>
  </div>  
</template>

<script>
import { editData } from '@/utils/editData.js';
import { postData } from '@/utils/postData.js';
import { fetchData } from '@/utils/fetchData.js';
import { deleteData } from '@/utils/deleteData.js';
import { loadFromLocalStorage } from '@/utils/loadFromLocalStorage.js';

import Swal from 'sweetalert2';
import ModalBasic from '@/components/ModalBasic.vue';
import DropdownFull from '@/components/DropdownFull.vue';
import SubCategoriesTable from './SubCategoriesTable.vue';
import StepForm from '@/partials/products/detail/StepPanel/StepForm.vue'


export default {
  name: 'StepsPanel',
  components: {  
    StepForm,
    ModalBasic,
    DropdownFull,
    SubCategoriesTable,
  },
  props: {
    productId: String,
    steps_: Array,
    productSteps_: Array,
    canEdit: {
      type: Boolean,
      default: function(){
        return false
      }
    },
  },
  data(){
    return {
      addStepModalOpen: false,
      roles: [],
      notifications: [],
      steps: this.steps_,
      productSteps: this.productSteps_,
      isLoading: false,
      isEditing: false,
      editingId: null,
      stepIndex: null,
      componentKey: 0,
      step: {
          name: null,
          description: null,
          required: false,
          type: 'UserTodo',
          roleIds: [],
          blockedBy: [],
          notifications: [],
          dueDateDays: null,
          generatesEvent: false,
          hasDocument: false,
          formats: ['.pdf'],
      },
      formData: {
        step: this.steps_?[0] : null,
      },
    }
  },
  methods: {
    async addStep(body){
      const response = await postData('steps', body, this);
      if (!response) {
          return
      };
      this.steps.push(response);
      this.formData.step = response;
      await this.addStepToProduct();
      this.clean()
      this.addStepModalOpen = false;
      this.componentKey++
    },
    async deleteProductStep(index){
      const id = this.productSteps[index].id
      const response = await deleteData('product-steps', id, this, 'El paso del producto se a eliminado exitosamente.', true, true)
      if(!response){
          return
      }
      this.productSteps.splice(index, 1);
      localStorage.setItem('productSteps', JSON.stringify(this.productSteps));
    },
    async addStepToProduct(){
      const body = {
        productId: this.productId,
        stepId: this.formData.step.id
      }
      const response = await postData('product-steps', body, this);
      if (!response) {
          return
      };
      Swal.fire({
        icon: 'success',
        title: 'Exito!',
        text: 'El paso se agrego al producto.',
      });
      this.productSteps.push(response);
      localStorage.setItem('productSteps', JSON.stringify(this.productSteps));
    },
    updateSelectedStep(step){
      this.formData.step = step;
    },
    async getData() {
      Promise.all([
        fetchData(`notifications`, 'notifications', 'notifications', this),
        fetchData(`roles`, 'userRoles', 'roles', this)
      ]).then((results) => {  
        this.componentKey++
      })
        return
    },
    editStep(step){
      
      this.isEditing = true;
      this.editingId = step.id;
      this.stepIndex = step.index;
      
      this.step = {
        name: step.step.name,
        description: step.step.description,
        required: step.step.required,
        type: step.step.type,
        roleIds: step.step.roleIds ?? [],
        blockedBy: step.step.blockedBy ?? [],
        dueDateDays: step.step.dueDateDays,
        generatesEvent: step.step.generatesEvent,
        hasDocument: step.step.hasDocument,
        formats: step.step.formats?? [],
        notifications: step.step.notifications ?? [],
      }
      this.componentKey++
      this.addStepModalOpen = true;
    },
    clean(){
      this.editingId = null;
      this.isEditing = false;
      this.stepIndex = null;
      this.step = {
          name: null,
          description: null,
          required: false,
          type: 'UserTodo',
          roleIds: [],
          blockedBy: [],
          notifications: [],
          dueDateDays: null,
          generatesEvent: false,
          hasDocument: false,
          formats: ['.pdf'],
      }
      this.componentKey++;
    },
    async submitEdit(){
      await editData(`product-steps/${this.editingId}`, this.step, 'El paso se ha guardado con exito.', 'productSteps', 'productSteps', this, true);
    }
  },
  created(){
    loadFromLocalStorage('roles', 'roles', this);
    loadFromLocalStorage('notifications', 'notifications', this);
    this.getData();
  }
}
</script>
<template>
  <div class="relative inline-flex w-full">
    <button
      ref="trigger"
      class="btn w-full justify-between min-w-44 bg-white dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-500 hover:text-slate-600 dark:text-slate-300 dark:hover:text-slate-200"
      aria-label="Select date range"
      aria-haspopup="true"
      @click.stop.prevent="dropdownOpen = !dropdownOpen"
      :aria-expanded="dropdownOpen"
    >
      <span class="flex items-center">
        <span>{{ getPropertyMethod(dropdownOptions[selected], propertyKey) }}</span>
      </span>
      <svg
        class="shrink-0 ml-1 fill-current text-slate-400 absolute right-3"
        width="11"
        height="7"
        viewBox="0 0 11 7"
      >
        <path d="M5.4 6.8L0 1.4 1.4 0l4 4 4-4 1.4 1.4z" />
      </svg>
    </button>
    <transition
      enter-active-class="transition ease-out duration-100 transform"
      enter-from-class="opacity-0 -translate-y-2"
      enter-to-class="opacity-100 translate-y-0"
      leave-active-class="transition ease-out duration-100"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        v-show="dropdownOpen"
        class="z-10 absolute top-full left-0 w-full bg-white dark:bg-slate-800 border border-slate-200 dark:border-slate-700 py-1.5 rounded shadow-lg overflow-hidden mt-1"
      >
        <div
          ref="dropdown"
          class="font-medium text-sm text-slate-600 dark:text-slate-300 divide-y divide-slate-200 dark:divide-slate-700"
          @focusin="dropdownOpen = true"
          @focusout="dropdownOpen = false"
        >
          <!-- Search Input -->
          <div class="px-3 py-2">
            <input
              class="form-input w-full"
              type="text"
              v-model="searchTerm"
              placeholder="Buscar..."
            />
          </div>

          <!-- Dropdown Options -->
          <button
            v-for="(option, index) in filteredOptions"
            :key="option.id"
            class="flex items-center justify-between w-full hover:bg-slate-50 dark:hover:bg-slate-700/20 py-2 px-3 cursor-pointer"
            :class="index === selected && 'text-indigo-500'"
            @click.stop.prevent="selectOption(option)"
          >
            <span>{{ getPropertyMethod(option, propertyKey) }}</span>
            <svg
              class="shrink-0 ml-2 fill-current text-indigo-400"
              :class="option.id !== selected && 'invisible'"
              width="12"
              height="9"
              viewBox="0 0 12 9"
            >
              <path
                d="M10.28.28L3.989 6.575 1.695 4.28A1 1 0 00.28 5.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28.28z"
              />
            </svg>
          </button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { ref, computed, onMounted, onUnmounted } from 'vue';
import { getProperty } from '@/utils/getProperty.js';

export default {
  name: 'DropdownFullQuestions',
  props: {
    dropdownOptions: {
      type: Array,
      default: () => [],  // Default to an empty array
    },
    propertyKey: {
      type: String,
      required: true,  // Make sure this prop is passed
    },
  },
  setup(props, { emit }) {
    const dropdownOpen = ref(false);
    const trigger = ref(null);
    const dropdown = ref(null);
    const selected = ref(0);
    const searchTerm = ref('');

    // Close dropdown on click outside
    const clickHandler = ({ target }) => {
      if (!dropdownOpen.value || dropdown.value.contains(target) || trigger.value.contains(target)) return;
      dropdownOpen.value = false;
    };

    // Close dropdown when the Esc key is pressed
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen.value || keyCode !== 27) return;
      dropdownOpen.value = false;
    };

    onMounted(() => {
      document.addEventListener('click', clickHandler);
      document.addEventListener('keydown', keyHandler);
    });

    onUnmounted(() => {
      document.removeEventListener('click', clickHandler);
      document.removeEventListener('keydown', keyHandler);
    });

    // Filtered options based on search term
    const filteredOptions = computed(() => {
      if (!searchTerm.value) return props.dropdownOptions;  // Return full options if no search term
      return props.dropdownOptions.filter((option) =>
        getProperty(option, props.propertyKey)
          .toString()
          .toLowerCase()
          .includes(searchTerm.value.toLowerCase())
      );
    });
    const updateModel = (option) => {
      emit('update:model', option);  // Emit the selected option to the parent
    }
    // Handle option selection and map to original options
    const selectOption = (filteredOption) => {
      const originalIndex = props.dropdownOptions.findIndex(option =>
        getProperty(option, props.propertyKey) === getProperty(filteredOption, props.propertyKey)
      );

      if (originalIndex !== -1) {
        selected.value = originalIndex;  // Set the index from the original array
        dropdownOpen.value = false;
        searchTerm.value = '';
        updateModel(props.dropdownOptions[originalIndex]);  // Emit the selected option
      }
    };

    return {
      dropdownOpen,
      trigger,
      dropdown,
      selected,
      searchTerm,
      filteredOptions,
      selectOption,
    };
  },
  methods: {
    getPropertyMethod(item, propertyKey) {
      return getProperty(item, propertyKey);  // Use getProperty utility method
    },
  },
};

</script>
<template>
  <ModalBasic id="survey-form-modal" :modalOpen="modalOpen" @close-modal="modalOpen = false, clean()" :title="isEditing ? 'Editar Pregunta' : 'Agregar Pregunta'" :size="'w-1/2'">
    <!-- Modal content -->
    <SurveyForm :key="componentKey" :is-loading="isLoading" @handle-click:value="isEditing ? editSurveySubmit($event) : addSurvey($event)" @close-modal="modalOpen = false" :form-data_="survey" />
  </ModalBasic>
  <div class="grow">
    <!-- Panel body -->
    <div class="p-6 space-y-6">
      <h2 class="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-5">Formulario</h2>
      <form @submit.prevent="add">
        <div class="grid grid-cols-1 sm:grid-cols-6 gap-2">
            <div v-if="canEdit" class="sm:col-span-3" > 
              <label class="block text-sm font-medium mb-1">
                  {{ $t('labels.questions') }}:
              </label>
              <div class="flex w-full">
                  
                  <input v-if="this.questions?.length < 1" id="email" class="form-input w-full" type="text" placeholder="Crea al menos una pregunta" :disabled="true" />
                  
                  <DropdownFullQuestions v-else 
                  :property-key="'question'"
                  :dropdownOptions="this.questions" :model="this.formData.question" @update:model="updateSelectedQuestion" />

                  <div class="pr-2"></div>
                  <button class="text-white-500 hover:text-white-600 rounded-full">

                      <svg @click.stop="modalOpen = true, clean()" class="w-4 h-8 fill-current opacity-50 shrink-0" viewBox="0 0 16 16">
                          <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                      </svg>
                  </button>
              </div>
              <button :disabled="isLoading || this.surveys?.length < 1" @click.stop="addQuestionToProduct" class="btn bg-indigo-500 hover:bg-indigo-600 text-white mt-2">
                <span v-if="!isLoading">{{ $t('buttons.add') }}</span>
                <span v-else>
                  <font-awesome-icon :icon="['fas', 'spinner']" spin /> {{ $t('labels.loading') }}...
                </span>
              </button>
            </div>
            <div class="sm:col-span-3" > 
                <SubCategoriesTable 
                :can-edit="canEdit"
                @edit="editSurvey"
                :title="'Preguntas del producto'" 
                :empty-label="'Selecciona una pregunta del dropdown para agregarla a las preguntas requeridas para el producto'"
                :property-key="'survey.question'"
                :values="this.productQuestions" :is-loading="isLoading"
                @update:model="deleteProductSurvey"
                @updateorder:model=update
                />
            </div>
        </div>
    </form>
    </div>
  </div>  
</template>

<script>
import { editData } from '@/utils/editData.js';
import { postData } from '@/utils/postData.js';
import { deleteData } from '@/utils/deleteData.js';

import Swal from 'sweetalert2';
import ModalBasic from '@/components/ModalBasic.vue';
import SubCategoriesTable from './SubCategoriesTable.vue';
import DropdownFullQuestions from './DropdownFullQuestions.vue';
import SurveyForm from '@/partials/products/detail/SurveyPanel/SurveyForm.vue'

export default {
  name: 'SurveysPanel',
  components: {  
    ModalBasic,
    SurveyForm,
    SubCategoriesTable,
    DropdownFullQuestions,
  },
  props: {
    productid: String,
    surveys: Array,
    productSurveys: Array,
    canEdit: {
      type: Boolean,
      default: function(){
        return false
      }
    },
  },
  emits: ['forceRender'],
  data(){
    return {
      editingId: null,
      isEditing: false,
      componentKey: 0,
      modalOpen: false,
      questions: this.surveys,
      productQuestions: this.productSurveys,
      isLoading: false,
      survey: {
        question: null,
        options: null,
        required: false,
        type: 'text',
        pseudonym: null,
        fixedAnswer: null,
        requestDuringSignature: true,
      },
      formData: {
        question: this.surveys.length > 0 ?this.surveys[0] : null,
        expenses: []
      },
    }
  },
  methods: {
    clean(){
      this.survey = {
        question: null,
        options: null,
        required: false,
        type: 'text',
        pseudonym: null,
        fixedAnswer: null,
        requestDuringSignature: true,
      }
      this.isEditing = false;
      this.editingId = null;
      this.componentKey++;
    },
    editSurvey(survey){
      this.isEditing = true;
      this.editingId = survey.id;
      this.survey = survey.survey
      this.modalOpen = true;
      this.componentKey++;
    },
    async editSurveySubmit(formData){
      await editData(`product-surveys/${this.editingId}`, formData, 'La pregunta se ha guardado con exito.', 'productQuestions', 'productSurveys', this, true);
      this.clean()
    },
    async addSurvey(formData){
      const response = await postData('surveys', formData, this);
      if (!response) {
          return
      };
      this.questions.push(response);
      this.formData.question = response;
      await this.addQuestionToProduct();
      this.clean()
      this.modalOpen = false;
    },
    async deleteProductSurvey(index){
      const id = this.productQuestions[index].id
      const response = await deleteData('product-surveys', id, this, 'La pregunta del producto se a eliminado exitosamente.', true, true)
      if(!response){
          return
      }
      this.productQuestions.splice(index, 1);
      localStorage.setItem('productSurveys', JSON.stringify(this.productQuestions));
    },
    async addQuestionToProduct(){
      const body = {
        productId: this.productid,
        surveyId: this.formData.question.id
      }
      const response = await postData('product-surveys', body, this);
      if (!response) {
          return
      };
      Swal.fire({
        icon: 'success',
        title: 'Exito!',
        text: 'La pregunta se agrego al producto.',
      });
      this.productQuestions.push(response);
      localStorage.setItem('productSurveys', JSON.stringify(this.productQuestions));
    },
    async update(elements){
      const productId = this.$route.params.id;
      var body = [];
      for (let i = 0; i < elements.length; i++) {
        body.push({
          id: elements[i].id,
          index: i + 1,
          surveyId: elements[i].survey.id,
          productId: productId,
        });
      }
      const response = await editData(`product-surveys`, body, 'El orden se modifico correctamente.', 'productQuestions', 'productSurveys', this, true);
      if (!response) {
          return
      };
      this.productQuestions = response;
      localStorage.setItem('productSurveys', JSON.stringify(this.productQuestions));
      this.$emit('forceRender');
    },
    updateSelectedQuestion(question){
      this.formData.question = question;
    },
  }
}
</script>
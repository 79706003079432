<template>  
  <div class="space-y-8">
  <!-- White box -->
  <div class="bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700 p-5 min-w-60">
    <div class="grid md:grid-cols-2 xl:grid-cols-1 gap-6">
      <div>
        <ExtrasAccordion class="sm:col-span-6" :title="'Servicios'">
          <DropdownRolesFilter class="mb-2 w-full" label="Servicios" :options="productsOptions" :model="selectedProducts" @update:model="updateProducts"/>
        </ExtrasAccordion>
      </div>
      <div>
         <!-- TODO: errors filter -->
        <ExtrasAccordion class="sm:col-span-6" :title="'General'">
          <!-- name, last name input -->
          <StringFilter :filter_="nameFilter" @update:filter="updateFilters($event, 'nameFilter')" :label="'Nombre'" ></StringFilter>
          <StringFilter :filter_="lastNameFilter" @update:filter="updateFilters($event, 'lastNameFilter')" :label="'Apellido'" ></StringFilter>
          <!-- email input -->
          <StringFilter :filter_="emailFilter" @update:filter="updateFilters($event, 'emailFilter')" :label="'Correo'" ></StringFilter>
          <!-- curp input -->
          <StringFilter :filter_="curpFilter" @update:filter="updateFilters($event, 'curpFilter')" :label="'CURP'" ></StringFilter>
          <!-- phone input -->
          <StringFilter :filter_="phoneFilter" @update:filter="updateFilters($event, 'phoneFilter')" :label="'Telefono'" ></StringFilter>
          <!--  DOB filter doble calendario --> 
          <div class="text-sm text-slate-800 dark:text-slate-100 font-semibold mb-3">Fecha Nacimiento</div>
          <DatepickerFilter :label="'De'" @update:filter="updateDateRange($event, 'dobStart')" :default-date_="startDobFilter" class="pb-2 w-full" />
          <DatepickerFilter :label="'Hasta'" @update:filter="updateDateRange($event, 'dobEnd')" :default-date_="endDobFilter" class="pb-2 w-full" />
        </ExtrasAccordion>
      </div>
      <div>
        <ExtrasAccordion class="sm:col-span-6" :title="'Estatus'">
          <CheckboxFilter 
          @update:filter="updateCheckbox($event, 'selectedRejectedOptionsFilter')"
          :label="''" :options="['Rechazados', 'Activos']" :model="this.filters.selectedRejectedOptionsFilter"/>
          <div class="text-sm text-slate-800 dark:text-slate-100 font-semibold mb-3">Fecha de Rechazo</div>
          <DatepickerFilter :label="'De'" @update:filter="updateDateRange($event, 'rejectedStart')" :default-date_="startRejectedFilter" class="pb-2 w-full" />
          <DatepickerFilter :label="'Hasta'" @update:filter="updateDateRange($event, 'rejectedEnd')" :default-date_="endRejectedFilter" class="pb-2 w-full" />
          <!-- isRejected checkbox, date rejected doble calendario-->
        </ExtrasAccordion>
      </div>
      <div>
        <ExtrasAccordion class="sm:col-span-6" :title="'Progreso'">
          <CheckboxFilter 
          @update:filter="updateCheckbox($event, 'selectedCallStatusFilter')"
          :label="'Llamada'" :options="this.callstatus" :model="this.filters.selectedCallStatusFilter"/>
          <CheckboxFilter 
          @update:filter="updateCheckbox($event, 'selectedAppointmentStatusFilter')"
          :label="'Cita'" :options="this.appointmentstatus" :model="this.filters.selectedAppointmentStatusFilter"/>
          <CheckboxFilter 
          @update:filter="updateCheckbox($event, 'selectedClosingStatusFilter')"
          :label="'Cierre'" :options="this.closingstatus" :model="this.filters.selectedClosingStatusFilter"/>
          <!-- apointment, call, closing checkbox -->
        </ExtrasAccordion>
      </div>
      <div>
        <ExtrasAccordion class="sm:col-span-6" :title="'Fuente/Vendedor'">
          <DropdownRolesFilter class="mb-2 w-full" label="Fuentes" :options="sourcesOptions" :model="selectedUsersSource" @update:model="updateUserSource"/>
          <DropdownRolesFilter class="w-full" label="Agentes" :options="usersOptions" :model="selectedUsersAgent" @update:model="updateUserAgent"/>
          <!-- source & agent checkbox -->
        </ExtrasAccordion>
      </div>
      <div>
        <ExtrasAccordion class="sm:col-span-6" :title="'Infonavit'">
          <!-- Balance filter slider -->
          <SliderFilter :label="'Saldo Cuenta'"  
          @update:filter="updateFilters($event, 'balanceFilter')"
          :min="Math.min(...balances)" :max="Math.max(...balances)" 
          :default-values_="[Math.min(...balances), Math.max(...balances)]"></SliderFilter>
          <!-- Puntos infonavit slider -->
          <SliderFilter :label="'Puntos'"  
          @update:filter="updateFilters($event, 'infonavitPointsFilter')"
          :min="Math.min(...points)" :max="Math.max(...points)" 
          :default-values_="[Math.min(...points), Math.max(...points)]"></SliderFilter>
            
        </ExtrasAccordion>
      </div>
      <div>
        <ExtrasAccordion class="sm:col-span-6" :title="'IMSS'">
          <!-- NSS filter text input -->
          <StringFilter :filter_="nssFilter" @update:filter="updateFilters($event, 'nssFilter')" :label="'NSS'" ></StringFilter>
          <!-- weeks. quoted, reintegrated slider -->
          <SliderFilter :label="'Semanas Cotizadas'"  
          @update:filter="updateFilters($event, 'quotedWeeksFilter')"
          :min="Math.min(...quotedweeks)" :max="Math.max(...quotedweeks)" 
          :default-values_="[Math.min(...quotedweeks), Math.max(...quotedweeks)]"></SliderFilter>
          <SliderFilter :label="'Semanas Descontadas'"  
          @update:filter="updateFilters($event, 'discountedWeeksFilter')"
          :min="Math.min(...discountedweeks)" :max="Math.max(...discountedweeks)" 
          :default-values_="[Math.min(...discountedweeks), Math.max(...discountedweeks)]"></SliderFilter>
          <SliderFilter :label="'Semanas Reintegradas'"  
          @update:filter="updateFilters($event, 'reintegratedWeeksFilter')"
          :min="Math.min(...reintegratedweeks)" :max="Math.max(...reintegratedweeks)" 
          :default-values_="[Math.min(...reintegratedweeks), Math.max(...reintegratedweeks)]"></SliderFilter>
          <!-- islaw73 2 checkbox -->
          <!-- <div class="pb-2">
            <div class="text-sm text-slate-800 dark:text-slate-100 font-semibold mb-3">Ley</div>
            <ul class="space-y-2">
              <li>
                <label v-for="option in ['73', '97']" :key="option" class="flex items-center">
                  <input type="checkbox" :checked="model[option]" class="form-checkbox" @change="updateModel(option)" />
                  <span class="text-sm text-slate-600 dark:text-slate-300 font-medium ml-2">{{option}}</span>
                </label>
              </li>
            </ul>
          </div> -->
          <!-- start labor activity date, end doble calendario -->
          <div class="text-sm text-slate-800 dark:text-slate-100 font-semibold mb-3">Comienzo Actividad Laboral</div>
          <DatepickerFilter :label="'De'" @update:filter="updateDateRange($event, 'laborStart')" :default-date_="startLaborFilter" class="pb-2 w-full" />
          <DatepickerFilter :label="'Hasta'" @update:filter="updateDateRange($event, 'laborEnd')" :default-date_="endLaborFilter" class="pb-2 w-full" />

          <div class="text-sm text-slate-800 dark:text-slate-100 font-semibold mb-3">Termino Actividad Laboral</div>
          <DatepickerFilter :label="'De'" @update:filter="updateDateRange($event, 'finishLaborStart')" :default-date_="startFinishLaborFilter" class="pb-2 w-full" />
          <DatepickerFilter :label="'Hasta'" @update:filter="updateDateRange($event, 'finishLaborEnd')" :default-date_="endFinishLaborFilter" class="pb-2 w-full" />
        </ExtrasAccordion>
      </div>
      <div>
        <ExtrasAccordion class="sm:col-span-6" :title="'Credito'">
          <CheckboxFilter 
          @update:filter="updateCheckbox($event, 'selectedCreditSituationFilter')"
          :label="'Situacion de Credito'" :options="this.creditsituation" :model="this.filters.selectedCreditSituationFilter"/>
          <CheckboxFilter 
          @update:filter="updateCheckbox($event, 'selectedCreditTypeFilter')"
          :label="'Tipo de Credito'" :options="this.creditype" :model="this.filters.selectedCreditTypeFilter"/>
          <CheckboxFilter 
          @update:filter="updateCheckbox($event, 'selectedCreditStatusFilter')"
          :label="'Estatus de Credito'" :options="this.creditstatus" :model="this.filters.selectedCreditStatusFilter"/>
          <!-- creditSituation, creditType, currently active, checkboxes -->
        </ExtrasAccordion>
      </div>
      <div>
        <ExtrasAccordion class="sm:col-span-6" :title="'Creacion/Edicion'">
          <div class="text-sm text-slate-800 dark:text-slate-100 font-semibold mb-3">Fecha Creacion</div>
          <DatepickerFilter :label="'De'" @update:filter="updateDateRange($event, 'createdStart')" :default-date_="startCreatedFilter" class="pb-2 w-full" />
          <DatepickerFilter :label="'Hasta'" @update:filter="updateDateRange($event, 'createdEnd')" :default-date_="endCreatedFilter" class="pb-2 w-full" />
          
          <div class="text-sm text-slate-800 dark:text-slate-100 font-semibold mb-3">Fecha Ultima Edicion</div>
          <DatepickerFilter :label="'De'" @update:filter="updateDateRange($event, 'updatedStart')" :default-date_="startUpdatedFilter" class="pb-2 w-full" />
          <DatepickerFilter :label="'Hasta'" @update:filter="updateDateRange($event, 'updatedEnd')" :default-date_="endUpdatedFilter" class="pb-2 w-full" />
          <!-- created at, updated at doble calendario -->
        </ExtrasAccordion>
      </div>
    </div>
  </div>
</div>
            
</template>

<script>
import { ref } from 'vue';

import ExtrasAccordion from '@/components/ExtrasAccordion.vue';
import StringFilter from '@/components/filters/StringFilter.vue';
import SliderFilter from '@/components/filters/SliderFilter.vue';
import CheckboxFilter from '@/components/filters/CheckboxFilter.vue';
import DatepickerFilter from '@/components/filters/DatePickerFilter.vue';
import DropdownRolesFilter from '@/partials/users/DropdownRolesFilter.vue';

export default {
  name: 'ProspectsSidebar',
  props:{
    products: Array,
    sources: Array,
    creditype: Array,
    creditsituation: Array,
    creditstatus: Array,
    closingstatus: Array,
    appointmentstatus: Array,
    callstatus: Array,
    balances: Array,
    points: Array,
    quotedweeks: Array,
    discountedweeks: Array,
    reintegratedweeks: Array,
    filters: Object,
  },
  components: {
    SliderFilter,
    StringFilter,
    CheckboxFilter,
    DatepickerFilter,
    ExtrasAccordion,
    DropdownRolesFilter,
  },
  setup() {

    const sidebarOpen = ref(false)

    return {
      sidebarOpen,
    }  
  },
  data(){
    const temp = localStorage.getItem('users');
    const users = temp ? JSON.parse(temp) :[];
    // TODO: check get of sources
    const usersOptions = users.map(user => user.username);
    const productsOptions = this.products;
    const sourcesOptions = this.sources.map(source => source.name);
    let selectedUsersSource = {};
    let selectedProducts = {};
    let selectedUsersAgent = {}
    for (const otpion in sourcesOptions) {
      selectedUsersSource[sourcesOptions[otpion]] = true;
    }
    for (const otpion in productsOptions) {
      selectedProducts[productsOptions[otpion]] = true;
    }
    for (const otpion in usersOptions) {
      selectedUsersAgent[usersOptions[otpion]] = true;
    }
    return {
      value: [20, 40],
      usersOptions,
      productsOptions,
      sourcesOptions,
      nameFilter: '',
      lastNameFilter: '',
      emailFilter: '',
      curpFilter: '',
      phoneFilter: '',
      nssFilter: '',
      startDobFilter: null,
      endDobFilter: null,
      startRejectedFilter: null,
      endRejectedFilter: null,
      startLaborFilter: null,
      endLaborFilter: null,
      startFinishLaborFilter: null,
      endFinishLaborFilter: null,
      startCreatedFilter: null,
      endCreatedFilter: null,
      startUpdatedFilter: null,
      endUpdatedFilter: null,
      selectedUsersAgent,
      selectedUsersSource,
      selectedProducts,
    }
  },
  methods: {
    updateUserSource(updatedUser) {
      this.selectedUsersSource = { ...updatedUser };
      const update = {value: this.selectedUsersSource, propName: 'selectedUsersSource'}
      this.$emit(`update:checkboxfilters`, update);
    },
    updateProducts(updated) {
      this.selectedProducts = { ...updated };
      const update = {value: this.selectedProducts, propName: 'selectedProducts'}
      this.$emit(`update:checkboxfilters`, update);
    },
    updateUserAgent(updatedUser) {
      this.selectedUsersAgent = { ...updatedUser };
      const update = {value: this.selectedUsersAgent, propName: 'selectedUsersAgent'}
      this.$emit(`update:checkboxfilters`, update);
    },
    updateDateRange(value, propName) {
      const update = {value: value, propName: propName}
      this.$emit(`update:filters`, update);
    },
    updateFilters(value, propName) {
      const update = {value: value, propName: propName}
      this.$emit(`update:sliderfilters`, update);
    },
    updateCheckbox(option, propName) {
      const update = {value: option, propName: propName}
      this.$emit('update:checkboxfilters', update);
    },
  },
}
</script>
<template>
    <BasePage>
      <span v-if="isLoading">
        <font-awesome-icon :icon="['fas', 'spinner']" spin /> {{ $t('labels.loading') }}...
      </span>
      <div v-else >
        <div class="sm:flex sm:justify-between sm:items-center mb-8">
            <!-- Left: Title -->
            <PageTitle :title="`Prospectos ✨`" />
            
            <div class="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2 items-center">
              <BoardToggle :is-board="boardMode" @update:model="changeBoardMode"/>
              <AddMainButton v-if="showAdd" :title="'Agregar Prospecto'" :clickHandler="create" />
            </div>            
        </div>
        <div class="flex flex-col space-y-10 sm:flex-row sm:space-x-6 sm:space-y-0 md:flex-col md:space-x-0 md:space-y-10 xl:flex-row xl:space-x-6 xl:space-y-0 mt-9">
          <!-- Sidebar -->
          <ProspectsSidebar :key="this.componentKey" v-if="!isLoading && showFilters" 
          :balances="this.balances" :points="this.points" :quotedweeks="this.quotedweeks"
          :discountedweeks="this.discountedweeks" :reintegratedweeks="this.reintegratedweeks"
          :callstatus="this.callstatus" :appointmentstatus="this.appointmentstatus" :closingstatus="this.closingstatus"
          :creditstatus="this.creditstatus" :creditsituation="this.creditsituation" :creditype="this.creditype"
          :filters="filters"  :sources="this.sources" :products="this.uniqueProducts"
          @update:checkboxfilters="updateCheckBoxFilter"
          @update:filters="updateFilter"
          @update:sliderfilters="updateSliderFilter"/>
          <div class="w-full" >
            <ProspectsTable v-if="!isLoading" :title="'Prospectos'"
            :is-todo="isTodo" :is-in-progress="isInProgress" :is-completed="isCompleted" :is-rejected="isRejected"
            :is-board="boardMode" :can-delete="showDelete" :can-edit="showEdit" :can-visualize="showVisualize"
            :filters="filters"
            :sources="this.sources" :users="this.users"
            :prospects="this.prospects" :items-per-page="20" @delete-p="deleteP"/>
            <span v-else>
              <font-awesome-icon :icon="['fas', 'spinner']" spin /> {{ $t('labels.loading') }}...
            </span>
          </div>
        </div>
      </div>
    </BasePage>
  </template>
  
  <script>
  import { fetchData } from '@/utils/fetchData.js';
  import { loadFromLocalStorage } from '@/utils/loadFromLocalStorage.js';
  import { getUniqueValuesFilter } from '@/utils/getUniqueValuesFilter.js';
  import { hasSpecificPermission } from '@/utils/hasSpecificPermission.js';

  import BasePage from '@/components/BasePage.vue';
  import PageTitle from '@/components/PageTitle.vue';
  import BoardToggle from '@/components/BoardToggle.vue';
  import AddMainButton from '@/components/buttons/AddMainButton.vue'
  import ProspectsTable from '@/partials/prospects/ProspectsTable.vue';
  import ProspectsSidebar from '@/partials/prospects/ProspectsSidebar.vue';
  export default {
    name: 'ProspectsPage',
    components: {
      BasePage,
      PageTitle,
      BoardToggle,
      AddMainButton,
      ProspectsTable,
      ProspectsSidebar,
    },
    data(){
      return {
        boardMode: true,
        showFilters: false,
        showAdd: false,
        showEdit: false,
        showDelete: false,
        showVisualize: false,
        users: [],
        products: [],
        uniqueProducts: [],
        sources: [],
        componentKey: 0,
        isLoading: false,
        balances: [0],
        points: [0],
        quotedweeks: [0],
        discountedweeks: [0],
        reintegratedweeks: [0],
        filters: {
          nameFilter: '',
          lastNameFilter: '',
          emailFilter: '',
          curpFilter: '',
          phoneFilter: '',
          nssFilter: '',
          dobStart: null,
          dobEnd: null,
          rejectedStart: null,
          rejectedEnd: null,
          laborStart: null,
          laborEnd: null,
          finishLaborStart: null,
          finishLaborEnd: null,
          createdStart: null,
          createdEnd: null,
          updatedStart: null,
          updatedEnd: null,
          balanceFilter: [],
          infonavitPointsFilter: [],
          quotedWeeksFilter: [],
          discountedWeeksFilter: [],
          reintegratedWeeksFilter: [],
          selectedRejectedOptionsFilter: {'Rechazados': true, 'Activos': true},
          selectedCallStatusFilter: {},
          selectedAppointmentStatusFilter: {},
          selectedClosingStatusFilter: {},
          selectedCreditStatusFilter: {},
          selectedCreditSituationFilter: {},
          selectedCreditTypeFilter: {},
          selectedUsersAgent: {},
          selectedUsersSource: {},
          selectedProducts: {},

        },
        prospects: [],
        callstatus: [],
        appointmentstatus: [],
        closingstatus: [],
        creditstatus: [],
        creditsituation: [],
        creditype: [],
      }
    },
    methods: {
      isTodo(prospect) {
        return !prospect.isRejected && prospect.appointmentStatus === 'TODO' &&
          prospect.callStatus === 'TODO' &&
          prospect.closingStatus === 'TODO';
      },
      isInProgress(prospect) {
        const { appointmentStatus, callStatus, closingStatus, isRejected } = prospect;

        // Check if any status is neither 'TODO' nor 'DONE'
        const hasInprogressStatus = [appointmentStatus, callStatus, closingStatus].some(
          status => status !== 'TODO' && status !== 'DONE'
        );

        // Check if any status is 'DONE' while others are still 'TODO'
        const isPartiallyComplete = (
          (appointmentStatus === 'DONE' && (callStatus === 'TODO' || closingStatus === 'TODO')) ||
          (callStatus === 'DONE' && (appointmentStatus === 'TODO' || closingStatus === 'TODO')) ||
          (closingStatus === 'DONE' && (appointmentStatus === 'TODO' || callStatus === 'TODO'))
        );

        return (hasInprogressStatus || isPartiallyComplete) && !isRejected;
      },
      isCompleted(prospect) {
        return prospect.appointmentStatus === 'DONE' && 
          prospect.callStatus === 'DONE' && 
          prospect.closingStatus === 'DONE' && !prospect.isRejected
      },
      isRejected(prospect) {
        return prospect.isRejected;
      },
      async getData() {
        this.prospects = await fetchData(`prospects/${true}`, 'prospects', 'prospects', this);
        this.sources = await fetchData(`sources`, 'sources', 'sources', this);
        this.users = await fetchData(`users`, 'users', 'users', this);
        this.products = await fetchData(`products/see`, 'products', 'products', this);
        this.setFilters();
      },
      changeBoardMode(updated){
        this.boardMode = updated;
        localStorage.setItem('boardMode', updated);
      },
      
      create(){
        this.$router.push('/prospects/create-prospect');
      },
      updateFilter(updated){
        this.filters[updated.propName] = updated.value !==  null ? updated.value['0'] : null;
      },
      updateSliderFilter(updated){
        this.filters[updated.propName] = updated.value;
      },
      updateCheckBoxFilter(updated){
        this.filters[updated.propName] = { ...updated.value };
      },
      async deleteP(id){
        const updatedProspects = this.prospects.filter((prospect) => prospect.id !== id);
        localStorage.setItem('prospects', JSON.stringify(updatedProspects));
        this.prospects = this.prospects.filter((prospect) => prospect.id !== id)
      },
      setFilters(){
        const balances = this.prospects.map(obj => obj.balance).filter(balance => typeof balance === 'number');
        const points = this.prospects.map(obj => obj.infonavitPoints).filter(infonavitPoints => typeof infonavitPoints === 'number');
        const quotedweeks = this.prospects.map(obj => obj.quotedWeeks).filter(quotedWeeks => typeof quotedWeeks === 'number');
        const discountedweeks = this.prospects.map(obj => obj.discountedWeeks).filter(discountedWeeks => typeof discountedWeeks === 'number');
        const reintegratedweeks = this.prospects.map(obj => obj.reintegratedWeeks).filter(reintegratedWeeks => typeof reintegratedWeeks === 'number');
        this.balances = balances.length === 0 ? [0] : balances;
        this.points = points.length === 0 ? [0] : points;
        this.quotedweeks = quotedweeks.length === 0 ? [0] : quotedweeks;
        this.discountedweeks = discountedweeks.length === 0 ? [0] : discountedweeks;
        this.reintegratedweeks = reintegratedweeks.length === 0 ? [0] : reintegratedweeks;
        this.balanceFilter = [Math.min(...balances), Math.max(...balances)];
        this.infonavitPointsFilter = [Math.min(...points), Math.max(...points)];
        this.quotedWeeksFilter = [Math.min(...quotedweeks), Math.max(...quotedweeks)];
        this.discountedWeeksFilter = [Math.min(...discountedweeks), Math.max(...discountedweeks)];
        this.reintegratedWeeksFilter = [Math.min(...reintegratedweeks), Math.max(...reintegratedweeks)];
        const { uniqueValues: callstatus, filter: selectedCallStatusFilter } = getUniqueValuesFilter(this.prospects, 'callStatus');
        const { uniqueValues: appointmentstatus, filter: selectedAppointmentStatusFilter } = getUniqueValuesFilter(this.prospects, 'appointmentStatus');
        const { uniqueValues: closingstatus, filter: selectedClosingStatusFilter } = getUniqueValuesFilter(this.prospects, 'closingStatus');
        const { uniqueValues: creditstatus, filter: selectedCreditStatusFilter } = getUniqueValuesFilter(this.prospects, 'creditStatus');
        const { uniqueValues: creditsituation, filter: selectedCreditSituationFilter } = getUniqueValuesFilter(this.prospects, 'creditSituation');
        const { uniqueValues: creditype, filter: selectedCreditTypeFilter } = getUniqueValuesFilter(this.prospects, 'creditType');
        const { uniqueValues: sources, filter: selectedUsersSource } = getUniqueValuesFilter(this.sources, 'name');
        const { uniqueValues: users, filter: selectedUsersAgent } = getUniqueValuesFilter(this.users, 'username');
        const { uniqueValues: uniqueProducts, filter: selectedProducts } = getUniqueValuesFilter(this.prospects.map(obj => obj.Products).flat(), 'name');
        this.callstatus = callstatus;
        this.appointmentstatus = appointmentstatus;
        this.closingstatus = closingstatus;
        this.creditstatus = creditstatus;
        this.creditsituation = creditsituation;
        this.creditype = creditype;
        this.uniqueProducts = uniqueProducts;
        this.filters.selectedCallStatusFilter = selectedCallStatusFilter;
        this.filters.selectedAppointmentStatusFilter = selectedAppointmentStatusFilter;
        this.filters.selectedClosingStatusFilter = selectedClosingStatusFilter;
        this.filters.selectedCreditStatusFilter = selectedCreditStatusFilter;
        this.filters.selectedCreditSituationFilter = selectedCreditSituationFilter;
        this.filters.selectedCreditTypeFilter = selectedCreditTypeFilter;
        this.filters.selectedUsersSource = selectedUsersSource;
        this.filters.selectedUsersAgent = selectedUsersAgent;  
        this.filters.selectedProducts = selectedProducts;        
      }
    },
    async created() {
      hasSpecificPermission('showFilters', 'page-filter', ["view_prospects_filter"], this)
      hasSpecificPermission('showAdd', 'add', ["add_prospect"], this)
      hasSpecificPermission('showEdit', 'edit', ["edit_prospect"], this)
      hasSpecificPermission('showDelete', 'delete', ["delete_prospect"], this)
      hasSpecificPermission('showVisualize', 'page', ["view_prospects_detail_page"], this)
      this.boardMode = JSON.parse(localStorage.getItem('boardMode')) ?? true;
      loadFromLocalStorage('prospects', 'prospects', this);
      loadFromLocalStorage('users', 'users', this);
      loadFromLocalStorage('products', 'products', this);
      loadFromLocalStorage('sources', 'sources', this);
      this.setFilters();

      this.getData();
    },
  }
  </script>
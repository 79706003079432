// src/data/prospectData.js
export function createProspectComponentData() {
    return {
      selectedSection: 'section1',
      modifyAgent: false,
      showAddSources: false,
      showAdd: false,
      products: [],
      documentLoadingSuccess: false,
      componentKey: 0,
      showData: false,
      isLoading: false,
      formData: {
        client: {
          curp: '',
          email: '',
          phone: '',
          sourceId: null,
          agentId: null,
          automaticAsignation: true,
        },
        documentUrl: null,
        values: [],
        aplicableProducts: [],
        errors: [],
        avaclick: {
          address: '',
          coordinates: { lat: null, lng: null },
          construction: null,
          constructionNA: true,
          terrain: null,
          terrainNA: true,
          typeProperty: 3,
          rooms: 0,
          bathrooms: 0,
          constructionYear: 2024,
          price: null,
          kitchen: true,
          priceNA: true,
          conservation: 2,
        },
        folio: null,
        twins: [],
        selectedImages: [],
        valuations_: []
      }
    };
  }
  